#pro {
    padding-top: 120px;
    /* Adjust based on your navbar height */
}

.gallery-container {
    position: relative;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensures buttons don't overflow */
}

.gallery-img {
    max-width: 100%; /* Ensures image doesn't overflow container */
}

.gallery-btn {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 24px;
    padding: 12px;
    border-radius: 50%;
}

.gallery-btn.prev {
    left: 10px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
}

.gallery-btn.next {
    right: 10px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
}

/* Media queries for small devices */
@media screen and (max-width: 1024px) {
    .gallery-btn.prev,
    .gallery-btn.next {
        font-size: 18px; /* Adjust button size for smaller screens */
        padding: 8px; /* Adjust button padding for smaller screens */
    }
}

.video-container {
    width: 100%;
    max-width: 800px; /* Adjust this value as needed */
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (min-width: 768px) {
    .video-container {
      max-width: 90%;
    }
  }
  