#home {
    padding-top: 100px;
    /* Adjust based on your navbar height */
}


@keyframes iconMovement {
    0% {
        transform: translateY(-50%);
        opacity: 0;
    }

    50% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(50%);
        opacity: 0;
    }
}

.hero {
    position: relative;
    height: 150vh;
}


.carousel {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* Ensure images don't overflow the carousel */
}

.slide {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
}

.slide.active {
    display: block;
}

.slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensure images cover the entire slide */
}




.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.dot-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: #000;
}

.news-card-wrapper {
    width: 100%;
    height: 30rem;
    /* Adjust the height as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 0.5rem;
    overflow: hidden;
}

.news-card-wrapper:hover {
    transform: scale(1.05);
    /* Increasing scale on hover */
}

.news-card-image {
    width: 70%;
    height: 70%;
    object-fit: fill;
}

@media screen and (max-width: 1024px) {
    .hero {
        height: auto;
        /* Set height to auto for small screens */
        padding-bottom: 20px;
        /* Add some bottom padding to the hero section */
    }

    #home {
        padding-top: 90px;
        /* Adjust based on your navbar height */
    }

    .carousel {
        position: relative;
        width: 100%;
        height: auto;
        /* Adjust height to auto */
        overflow: hidden;
        /* Ensure content doesn't overflow */
    }

    .slide {
        position: relative;
        width: 200%;
        /* Make the slide twice as wide */
        height: 200%;
        /* Make the slide twice as tall */
        display: none;
    }

    .slide.active {
        display: block;
    }

    .slide-image {
        width: 50%;
        /* Set width to 50% for twice as wide */
        height: auto;
        /* Set height to auto to maintain aspect ratio */
        object-fit: cover;
        /* Ensure image covers the entire slide */
    }

    .text-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .dot-container {
        position: absolute;
        bottom: 1;
        /* Adjust dot container position for small screens */
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
        /* Reduce margin bottom for small screens */
    }

    .dot {
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        margin: 0 5px;
        /* Adjust margin to create space between dots */
        cursor: pointer;
    }

    .dot.active {
        background-color: #000;
    }
}


/* Target all sections and set width to 90% */
#about,
#projects,
#news,
#contact,
#support {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 20px;
    /* Adjust as needed */
    padding-bottom: 20px;
    /* Adjust as needed */
}

@media (min-width: 768px) and (max-width: 1024px) {
    .grid-cols-1.md\:grid-cols-3 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        /* Display one column on tablet-sized screens */
    }
}

@media (max-width: 767px) and (max-width: 1024px){
    #projects {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        /* Display one column on smaller devices */
    }
}
