@keyframes crawl {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .text-crawl {
    display: inline-block;
    white-space: nowrap;
    animation: crawl 10s linear infinite;
    /* Ensuring width is flexible */
    width: max-content; /* Or use an explicit width if needed */
  }
  