.nav-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

/* Media Query for Small Devices */
@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
    }

    .footer_socials {
        margin-bottom: 2.6rem;
    }
}